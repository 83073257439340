
.side-notification-panel {
  position: fixed;
  top: 2rem;
  right: 2rem;
  margin-top: 7.2rem;
  width: 38.4rem;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    & {
      left: 1rem;
      width: calc(100vw - 2rem);
    }
  }
}
