@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .dsf-text-jumbo-semibold {
    font-family: Inter;
    font-weight: 600;
    font-size: calc(4.8 * var(--dsf-base-size));
    letter-spacing: calc(-0.072 * var(--dsf-base-size));
    line-height: calc(5.6 * var(--dsf-base-size));
  }

  .dsf-text-xl-semibold {
    font-family: Inter;
    font-weight: 600;
    font-size: calc(3.2 * var(--dsf-base-size));
    letter-spacing: calc(0.008 * var(--dsf-base-size));
    line-height: calc(4 * var(--dsf-base-size));
  }

  .dsf-text-xs {
    font-family: Inter;
    font-weight: 400;
    font-size: calc(1.4 * var(--dsf-base-size));
    letter-spacing: calc(0.007000000000000001 * var(--dsf-base-size));
    line-height: calc(1.8 * var(--dsf-base-size));
  }

  .dsf-text-xs-semibold {
    font-family: Inter;
    font-weight: 600;
    font-size: calc(1.4 * var(--dsf-base-size));
    letter-spacing: calc(0.0035000000000000005 * var(--dsf-base-size));
    line-height: calc(1.8 * var(--dsf-base-size));
  }

  .dsf-text-xxs {
    font-family: Inter;
    font-weight: 400;
    font-size: calc(1.2 * var(--dsf-base-size));
    letter-spacing: calc(0.006 * var(--dsf-base-size));
    line-height: calc(1.6 * var(--dsf-base-size));
  }

  .dsf-text-xxs-semibold {
    font-family: Inter;
    font-weight: 600;
    font-size: calc(1.2 * var(--dsf-base-size));
    letter-spacing: calc(0.018 * var(--dsf-base-size));
    line-height: calc(1.6 * var(--dsf-base-size));
  }

  .dsf-text-s {
    font-family: Inter;
    font-weight: 400;
    font-size: calc(1.6 * var(--dsf-base-size));
    letter-spacing: calc(0.0024000000953674316 * var(--dsf-base-size));
    line-height: calc(2.4 * var(--dsf-base-size));
  }

  .dsf-text-m-semibold {
    font-family: Inter;
    font-weight: 600;
    font-size: calc(2 * var(--dsf-base-size));
    letter-spacing: calc(0.0030000001192092896 * var(--dsf-base-size));
    line-height: calc(2.8 * var(--dsf-base-size));
  }

  .dsf-text-s-medium {
    font-family: Inter;
    font-weight: 500;
    font-size: calc(1.6 * var(--dsf-base-size));
    letter-spacing: calc(0.0024000000953674316 * var(--dsf-base-size));
    line-height: calc(2.4 * var(--dsf-base-size));
  }

  .dsf-text-l-semibold {
    font-family: Inter;
    font-weight: 600;
    font-size: calc(2.4 * var(--dsf-base-size));
    letter-spacing: calc(0 * var(--dsf-base-size));
    line-height: calc(3.2 * var(--dsf-base-size));
  }

  .dsf-text-xxl-semibold {
    font-family: Inter;
    font-weight: 600;
    font-size: calc(4 * var(--dsf-base-size));
    letter-spacing: calc(-0.02 * var(--dsf-base-size));
    line-height: calc(4.8 * var(--dsf-base-size));
  }
}