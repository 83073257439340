*,
body {
  margin: 0;
}

html,
body {
  height: 100%;
}

html {
  font-size: 10px;
}

body {
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
